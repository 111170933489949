




































import { DigInputText } from '@digithia/input/text'
import { Component, Vue } from 'vue-property-decorator'
import { Utils } from '../../utils/Utils'

@Component({
  components: {},
})
export default class ViewActivateAccount extends Vue {
  password = ''
  passwordConfirmation = ''

  get confirmationMatching(): boolean {
    return this.password === this.passwordConfirmation
  }

  get validPassword(): boolean {
    return !!this.password && this.password.length > 4
  }

  mounted() {
    const inputPassword = document.querySelector('#password') as DigInputText
    inputPassword.validator = (value: string) => {
      return !!value && value.length > 4
    }
    inputPassword.errors.validator = `Le mot de passe est invalide : minimum 5 caractères`
    const inputConfirmation = document.querySelector(
      '#passwordConfirmation',
    ) as DigInputText
    inputConfirmation.validator = (value: string) => {
      return value === this.password
    }
    inputConfirmation.errors.validator = `La confirmation ne correspond pas au mot de passe`
  }

  validate(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.activateAccount()
    }
  }

  activateAccount() {
    const valid = Utils.checkInputsValidity('dig-input-text')
    if (!valid) {
      this.$store.dispatch('toaster/toast', {
        type: 'warning',
        message: `Le formulaire est invalide`,
      })
    } else {
      this.$store
        .dispatch('activateAccount', {
          userId: this.$store.getters.loggedUser.id,
          password: this.password,
        })
        .then(json => {
          this.$store.dispatch('toaster/toast', json)

          if (json.success) {
            this.$store.dispatch('logout')
            this.$router.push({ name: 'Login' })
          }
        })
    }
  }
}
